import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const DEBUG = false;

window.restaurantNumber = 2;
window.restaurantName = `Bowls Brugge`

if (DEBUG) {
  window.baseDomain = 'https://booking-system-test-02d7e73d8f66.herokuapp.com/';
} else {
  window.baseDomain = 'https://booking-system-euro-3c75b18a7c36.herokuapp.com/';
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();