import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Alert, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import './Reservation.css';

const Giftcards = () => {
  const [giftcards, setGiftcards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('digital');
  const [error, setError] = useState(null);
  const restaurantNumber = window.restaurantNumber;
  const buttonColor = window.colour || '#382d2b';

  useEffect(() => {
    const fetchGiftcards = async () => {
      try {
        const response = await fetch(window.baseDomain + `giftcards/restaurant/${restaurantNumber}/`);
        const data = await response.json();
        data.sort((a, b) => b.amount - a.amount);
        setGiftcards(data.map(card => ({
          ...card,
          title: `Cadeaubon €${card.amount}`,
          description: 'Het perfecte cadeau om een speciaal persoon te verrassen.'
        })));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching giftcards:', error);
        setLoading(false);
      }
    };

    fetchGiftcards();
  }, [restaurantNumber]);

  const handleShowModal = (card) => {
    setSelectedCard(card);
    setShowModal(true);
  };

  const handleCheckout = async () => {
    const isPhysical = (deliveryOption === 'pickup_pay_now') || (deliveryOption === 'pickup_pay_later');
    const payNow = (deliveryOption === 'pickup_pay_now') || (deliveryOption === 'digital');

    console.log('First Name:', firstName);
    console.log('Last Name:', lastName);
    console.log('Email:', email);
    console.log('Physical Gift:', isPhysical);
    console.log('Pay Now:', payNow);
    console.log('Selected Card Amount:', selectedCard.amount);

    try {
      const res = await axios.post(`${window.baseDomain}giftcards/checkout/${restaurantNumber}/${selectedCard.amount}/`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        physical_gift: isPhysical,
        pay_in_advance: payNow,
      });

      console.log('Response:', res.data);

      if (payNow) {
        window.location.href = res.data._links.checkout.href;
      } else {
        window.location.href = '/thank-you';
      }
    } catch (err) {
      console.error('Error:', err);
      setError(err.message);
    }
  };

  const renderGiftCards = () => {
    if (giftcards.length === 0) {
      return <Alert variant="warning" className="text-center">Er zijn momenteel geen cadeaubonnen beschikbaar.</Alert>;
    }

    return giftcards.map((card) => (
      <Col key={card.id} xs={12} className="mb-4">
        <Card className="giftcard-card">
          <Card.Body className="d-flex flex-column align-items-center">
            <Card.Title className="text-center">{card.title}</Card.Title>
            <Card.Text className="text-center">{card.description}</Card.Text>
            <Card.Text className="text-center"><small>Bedrag: €{card.amount}</small></Card.Text>
            <div className="d-flex justify-content-center w-100 mt-2">
              <Button 
                variant="primary" 
                className="mx-1 choose-button"
                style={{ backgroundColor: buttonColor, borderColor: buttonColor, padding: '0.25rem 0.5rem', fontSize: '0.9rem' }}
                onClick={() => handleShowModal(card)}
              >
                Kies
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <Container className="reservation-container">
      <div className='fullscreen-bg-reservation'></div>

      <Row className="justify-content-md-center align-items-center mb-4">
        <Col xs={12}>
          <Card className="title-card">
            <Card.Body>
              <h1 className="text-center">Cadeaubonnen {window.restaurantName}</h1>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="justify-content-md-center align-items-center">
        {loading ? <p>Loading...</p> : renderGiftCards()}
      </Row>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Voer uw gegevens in</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formFirstName">
              <Form.Label>Voornaam</Form.Label>
              <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mt-3" controlId="formLastName">
              <Form.Label>Achternaam</Form.Label>
              <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mt-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mt-3" controlId="formDeliveryOption">
              <Form.Label>Leveringsoptie</Form.Label>
              <Form.Control as="select" value={deliveryOption} onChange={(e) => setDeliveryOption(e.target.value)}>
                <option value="digital">Digitale bon</option>
                <option value="pickup_pay_now">Ophalen en nu betalen</option>
                <option value="pickup_pay_later">Ophalen en later betalen</option>
              </Form.Control>
            </Form.Group>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Annuleren</Button>
          <Button variant="primary" onClick={handleCheckout}>Ga verder</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Giftcards;
